/* eslint-disable import/no-unresolved */
import React from 'react'

// Images
import blueChevronLeft from 'img/snel_blue_chevron_left.svg'
import blueChevronRight from 'img/snel_blue_chevron_right.svg'
import whiteChevronDown from 'img/snel_white_chevron_down.svg'

// Keyframes
// import { keyFramePulsing } from 'styles/Keyframes'

// Third Party
import styled, { css } from 'styled-components'

const NextArrowWrapper = styled.div`
  cursor: pointer;

  ${props => props.middle ? css`
    top: 0;
    bottom: 0;
    right: -50px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  ` : css`
    top: 85px;
    right: -50px;
  `}

  ${props => props.white && css`
    @media(max-width: 609px) {
      right: 15px;

      img {
        transform: rotate(270deg);
        height: 20px;
        width: 20px;
      }
    }
  `}
`

export const NextArrow = ({ white, middle, className, onClick }) => {
  return (
    <NextArrowWrapper white={white} middle={middle} onClick={onClick} className={`position-absolute ${className ? `${className}` : ``}`}>
      {white ? (
        <img src={whiteChevronDown} alt="" />
      ) : (
        <img src={blueChevronRight} alt="" />
      )}
    </NextArrowWrapper>
  )
}

const PrevArrowWrapper = styled.div`
  cursor: pointer;

  ${props => props.middle ? `
    top: 0;
    bottom: 0;
    left: -50px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  ` : `
    top: 85px;
    left: -50px;
  `}

  ${props => props.white ? `
    @media(max-width: 609px) {
      left: 15px;
      z-index: 10;

      img {
        transform: rotate(90deg);
        height: 20px;
        width: 20px;
      }
    }
  ` : `
  `}
`

export const PrevArrow = ({ white, middle, className, onClick }) => {
  return (
    <PrevArrowWrapper white={white} middle={middle} onClick={onClick} className={`position-absolute ${className ? `${className}` : ``}`}>
      {white ? (
        <img src={whiteChevronDown} alt="" />
      ) : (
        <img src={blueChevronLeft} alt="" />
      )}
    </PrevArrowWrapper>
  )
}